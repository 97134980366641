<template>
  <component :is="form === undefined ? 'div' : 'b-card'">
    <template>
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
          <b-row class="mt-3">
            <!-- Name -->
            <b-col
                cols="12"
                sm="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-form-group
                    label="Name"
                    label-for="name"
                    :state="getValidationState(validationContext)"
                >
                  <b-form-input
                      id="name"
                      v-model="form.name"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Title -->
            <b-col
                cols="12"
                sm="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="title"
                  rules="required"
              >
                <b-form-group
                    label="Title"
                    label-for="title"
                >
                  <b-form-input
                      id="title"
                      v-model="form.title"
                      :state="getValidationState(validationContext)"
                      trim
                      autocomplete="off"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Text -->
            <b-col cols="12">
              <validation-provider
                  #default="validationContext"
                  name="Text"
                  rules="required"
              >
                <b-form-group
                    label="text"
                    label-for="text"
                    :state="getValidationState(validationContext)"
                >
                  <b-form-textarea
                      v-model="form.text"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="(val) => val.value"
                      input-id="text"
                      rows="4"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Form Actions -->
            <b-col cols="12">
              <div class="d-flex mt-2">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="mr-2"
                    type="submit"
                >
                  {{ templateId ? 'Update' : 'Add' }}
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-danger"
                >
                  Reset
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </component>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, url } from '@validations'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
// repository
import EmailTemplateRepository from '@/abstraction/repository/emailTemplateRepository'
// random token
import useUsersList from '../user/useUsersList'
import userStoreModule from '../user/userStoreModule'
import Calendar from '@/views/components/calendar/Calendar'

const templateRepository = new EmailTemplateRepository()

export default {
  components: {
    Calendar,
    BTab,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BForm,
    BFormTextarea,
    BFormFile,
    BFormDatepicker,
    BImg,
    BMedia,
    BTabs,
    BCard,
    BAlert,
    BRow,
    BCol,
    vSelect,
    BButton,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    url,
    isLoading: false,
  }),
  setup() {
    const blankForm = {
      name: '',
      title: '',
      text: '',
    }
    const templateId = ref(router.currentRoute.params.id)
    const roleOptions = [
      {
        label: 'Admin',
        value: 'admin'
      },
      {
        label: 'Author',
        value: 'author'
      },
      {
        label: 'Editor',
        value: 'editor'
      },
      {
        label: 'Maintainer',
        value: 'maintainer'
      },
      {
        label: 'Subscriber',
        value: 'subscriber'
      },
    ]

    const planOptions = [
      {
        label: 'Basic',
        value: 'basic'
      },
      {
        label: 'Company',
        value: 'company'
      },
      {
        label: 'Enterprise',
        value: 'enterprise'
      },
      {
        label: 'Team',
        value: 'team'
      },
    ]
    const form = ref(blankForm)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const { refetchData } = useUsersList()
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(async () => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    onMounted(async () => {
      if (templateId.value) {
        form.value = await templateRepository.show(templateId.value)
      }
    })
    const resetform = () => {
      form.value = JSON.parse(JSON.stringify(blankForm))
    }
    const onSubmit = async () => {
      try {
        if (router.currentRoute.params.id) {
          await templateRepository.update(form.value.id, form.value)
        } else {
          await templateRepository.store(form.value)
        }
        await router.push({ name: 'apps-email-templates-list' })
      } catch (e) {
        console.log(e)
        return e
      }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetform)
    return {
      form,
      refetchData,
      onSubmit,
      templateId,
      refFormObserver,
      getValidationState,
      resetForm,
      avatarText,
      roleOptions,
      planOptions,
    }
  },
  watch: {
    '$route.params': function (to, from) {
      this.templateId = to.id
    },
  },
  methods: {},
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
